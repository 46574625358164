import React, { useEffect, useState } from "react";

const ScrollIcon = ({ ...props }) => {
  const [hideScroll, setHideScroll] = useState(false);

  const onScroll = () => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight";

    var percent = parseInt(
      ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100,
    );
    if (percent > 90) {
      setHideScroll(true);
    } else {
      setHideScroll(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", onScroll, false);
    }

    // returned function will be called on component unmount
    return () => {
      if (typeof window !== "undefined") {
        // you need to unbind the same listener that was binded.
        window.removeEventListener("scroll", onScroll, false);
      }
    };
  }, []);

  return (
    <div className={hideScroll ? "icon-scroll hidescroll" : "icon-scroll"}>
      <span>Scroll</span>
    </div>
  );
};

export default ScrollIcon;
