import React, { useState, useEffect } from "react";

import HamMenuItem from "./HamMenuItem";
import HamMenuAbout from "./HamMenuAbout";
import HamMenuMarkets from "./HamMenuMarkets";
import HamMenuProducts from "./HamMenuProducts";
import HamMenuServices from "./HamMenuServices";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const TopLevel = ({ isHamOpen }) => {
  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.3 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };
  const Menuvariants = {
    subMenuOpen: {
      x: "-200%",
      y: "-50%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subMenuClosed: {
      x: "-50%",
      y: "-50%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const [isOpen, setisOpen] = useState({
    about: false,
    market: false,
    service: false,
    product: false,
  });
  const [subMenu, setSubOpen] = useState(false);

  useEffect(() => {
    const timer2 = setTimeout(() => {
      !isHamOpen && setSubOpen(false);
      !isHamOpen &&
        setisOpen({
          about: false,
          market: false,
          service: false,
          product: false,
        });
    }, 400);
  }, [isHamOpen]);

  return (
    <LazyMotion features={loadFeatures}>
      <m.div
        variants={Menuvariants}
        animate={subMenu ? "subMenuOpen" : "subMenuClosed"}
        className="z-50 w-3/4 absolute text-left transform -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2"
      >
        <m.ul
          variants={variants}
          animate={isHamOpen ? "open" : "closed"}
          className="z-50 w-3/4 absolute text-left transform -translate-y-1/2 top-1/2 -translate-x-1/2 left-1/2"
        >
          <HamMenuItem
            title="About us"
            section="about"
            isOpen={isOpen}
            setisOpen={setisOpen}
            setSubOpen={setSubOpen}
          />
          <HamMenuItem
            title="Markets"
            section="market"
            isOpen={isOpen}
            setisOpen={setisOpen}
            setSubOpen={setSubOpen}
          />
          <HamMenuItem
            title="Services"
            section="service"
            isOpen={isOpen}
            setisOpen={setisOpen}
            setSubOpen={setSubOpen}
          />
          <HamMenuItem
            title="Products"
            section="product"
            isOpen={isOpen}
            setisOpen={setisOpen}
            setSubOpen={setSubOpen}
          />
        </m.ul>
      </m.div>
      <HamMenuAbout
        title="About"
        section="about"
        isOpen={isOpen}
        setisOpen={setisOpen}
        setSubOpen={setSubOpen}
        isHamOpen={isHamOpen}
      />
      <HamMenuMarkets
        title="Markets"
        section="market"
        isOpen={isOpen}
        setisOpen={setisOpen}
        setSubOpen={setSubOpen}
        isHamOpen={isHamOpen}
      />
      <HamMenuServices
        title="Services"
        section="service"
        isOpen={isOpen}
        setisOpen={setisOpen}
        setSubOpen={setSubOpen}
        isHamOpen={isHamOpen}
      />
      <HamMenuProducts
        title="Products"
        section="product"
        isOpen={isOpen}
        setisOpen={setisOpen}
        setSubOpen={setSubOpen}
        isHamOpen={isHamOpen}
      />
    </LazyMotion>
  );
};

export default TopLevel;
