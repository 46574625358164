import React, { useState, useEffect } from "react";
import AniLink from "./transLink";
import Logo from "../components/logo.js";
// import Search from '../components/search.js'
import { useIsClient } from "../utils/useIsClient";
import MenuAbout from "../components/menu/menuAbout.js";
import MenuServices from "../components/menu/menuServices.js";
import MenuMarkets from "../components/menu/menuMarkets.js";
import MenuProducts from "../components/menu/menuProducts.js";
import MenuInsights from "../components/menu/menuInsights.js";
import HamMenu from "../components/menu/hamMenu.js";

import MenuItem from "../components/menu/menuitem.js";
// import LanguageSwitcher from '../components/languageswitcher.js'

const Header = ({ location }) => {
  const [dimensions, setDimenions] = useState(0);
  const [isOpen, setisOpen] = useState({
    about: false,
    market: false,
    service: false,
    product: false,
    projects_insights: false,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      setDimenions(window.innerWidth);
      window.addEventListener(
        "resize",
        function (event) {
          setDimenions(window.innerWidth);
        },
        true,
      );
    }
  }, []);

  return (
    <>
      <nav className="w-full fixed h-14 top-0 left-0 z-50 bg-black">
        <div className="flex flex-row w-full text-white pt-2">
          <div className="xl:w-4/12 w-3/12 md:h-10 h-14 ml-4 md:ml-10 text-left justify-start">
            <AniLink to="/" paintDrip hex="#008768">
              <Logo />
            </AniLink>
          </div>
          {dimensions > 1024 ? (
            <>
              <div className="xl:w-5/12 w-6/12 h-10 pt-2 text-center justify-center hidden lg:grid grid-cols-4 gap-2">
                <MenuItem
                  title="About us"
                  section="about"
                  isOpen={isOpen}
                  setisOpen={setisOpen}
                />
                <MenuItem
                  title="Markets"
                  section="market"
                  isOpen={isOpen}
                  setisOpen={setisOpen}
                />
                <MenuItem
                  title="Services"
                  section="service"
                  isOpen={isOpen}
                  setisOpen={setisOpen}
                />
                <MenuItem
                  title="Products"
                  section="product"
                  isOpen={isOpen}
                  setisOpen={setisOpen}
                />
              </div>
              <div className="xl:w-4/12 md:w-3/12 h-10 text-right lg:flex hidden flex-row w-full text-white">
                <div className="w-full pt-2 pr-10 grow items-end flex-none h-10 text-right text-white ">
                  {/* <Search/> */}
                  <MenuItem
                    title="Projects & insights"
                    section="projects_insights"
                    isOpen={isOpen}
                    setisOpen={setisOpen}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </nav>
      {dimensions < 1024 ? (
        <div className="lg:hidden block">
          <HamMenu location={location} />
        </div>
      ) : (
        <div className="hidden lg:block">
          <MenuAbout
            title="About us"
            section="about"
            isOpen={isOpen}
            setisOpen={setisOpen}
          />
          <MenuMarkets
            title="Markets"
            section="market"
            isOpen={isOpen}
            setisOpen={setisOpen}
          />
          <MenuServices
            title="Services"
            section="service"
            isOpen={isOpen}
            setisOpen={setisOpen}
          />
          <MenuProducts
            title="Products"
            section="product"
            isOpen={isOpen}
            setisOpen={setisOpen}
          />
          <MenuInsights
            title="Projects & insights"
            section="projects_insights"
            isOpen={isOpen}
            setisOpen={setisOpen}
          />
        </div>
      )}
    </>
  );
};

export default Header;
