import React from "react";

const ProductName = ({ name, theme, mono }) => {
  return (
    <>
      <span className="font-digital font-bold">{name.split(".")[0]}</span>
      <span
        className={
          mono
            ? `font-digital font-bold`
            : `font-digital font-bold text-AECOM_EarthGreen`
        }
      >
        .
      </span>
      <span className={`font-digital font-normal text-${theme}`}>
        {name.split(".")[1]}
      </span>
    </>
  );
};

export default ProductName;
