import React, { useState } from "react";
import { Link } from "gatsby";
// import { motion, Transition, SVGMotionProps } from "framer-motion";

import { LazyMotion, m } from "framer-motion";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const Arrow = ({ isOpen, topMenu }) => {
  const variant = isOpen ? "opened" : "closed";
  const line2 = {
    closed: {
      rotate: -90,
    },
    opened: {
      rotate: 0,
    },
  };

  const line1 = {
    closed: {
      rotate: 90,
    },
    opened: {
      rotate: 0,
    },
  };

  const lineProps = {
    stroke: !topMenu ? "#000000" : "#ffffff",
    strokeWidth: 2,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    strokeLinecap: "round",
    strokeMiterlimit: "10",
  };

  return (
    <LazyMotion features={loadFeatures}>
      <div>
        <m.svg
          viewBox={`0 0 110 65`}
          overflow="visible"
          preserveAspectRatio="none"
          width="100%"
          height="100%"
        >
          <m.line
            x1="15"
            x2="55"
            y1="51"
            y2="11"
            variants={line1}
            {...lineProps}
          />
          <m.line
            x1="95"
            x2="55"
            y1="51"
            y2="11"
            variants={line2}
            {...lineProps}
          />
        </m.svg>
      </div>
    </LazyMotion>
  );
};

export default Arrow;
