import React, { useState } from "react";

import { useSelector } from "react-redux";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";
import {
  selectCurrentlocale,
  selectUiTranslation,
} from "../../slices/contactSlice";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const HamMenuItem = ({ title, section, isOpen, setisOpen, setSubOpen }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";

  const handleChange = () => {
    let isTemp = isOpen;
    for (const property in isTemp) {
      if (property === section) {
        setSubOpen(true);
        isTemp[section] = isTemp[section] ? false : true;
      } else {
        isTemp[property] = false;
      }
    }
    setisOpen((prevState) => ({
      ...prevState,
      ...isTemp,
    }));
  };

  return (
    <LazyMotion features={loadFeatures}>
      <m.li
        onClick={handleChange}
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        className="cursor-pointer text-2xl text-black mb-16 xsm:text-xl  xsm:mb-8 font-semibold transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left"
      >
        {uiTranslation[section][currentlocale]}
      </m.li>
    </LazyMotion>
  );
};
export default HamMenuItem;
