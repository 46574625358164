import React, { useState } from "react";
import { useSelector } from "react-redux";
import LinkLang from "../../utils/langLink.js";
import { LazyMotion, m } from "framer-motion";
import {
  selectCurrentlocale,
  selectUiTranslation,
} from "../../slices/contactSlice";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const SubMenu = ({ data, ham, linksection }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";

  const variant = data === null ? "closed" : "opened";

  const subListContainer = {
    closed: { opacity: 1, staggerChildren: 0.1 },
    opened: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const subListItem = {
    closed: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    opened: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  if (data !== null) {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          className={
            ham
              ? data.length > 8
                ? "grid w-full grid-cols-2 gap-y-2 gap-x-6 h-auto"
                : "grid w-full grid-cols-1 gap-y-1 h-auto"
              : "grid grid-cols-2 gap-x-4 gap-y-1 h-auto"
          }
          initial="closed"
          variants={subListContainer}
          animate={variant}
        >
          {data.map((document, index) => (
            <m.div
              key={`sub${index}`}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              variants={subListItem}
              className={
                ham
                  ? "font-light xsm:text-xs xsm:mb-1 text-base text-black mb-4 leading-tight transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left"
                  : "font-light xl:text-sm text-xs hsm:text-xs text-black hsm:h-9 hsm:pb-0 h-11 pb-2 leading-tight transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left"
              }
            >
              <LinkLang to={document.slug} section={linksection}>
                {document.title}
              </LinkLang>
            </m.div>
          ))}
        </m.div>
      </LazyMotion>
    );
  } else {
    return <></>;
  }
};

export default SubMenu;
