import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LinkLang from "../../utils/langLink.js";
import { getContinent } from "../../utils/";
import RelatedMenu from "../related/relatedMenu.js";
import { LazyMotion, m } from "framer-motion";
import { flow, orderBy, chunk, filter } from "lodash/fp";
import { useDispatch } from "react-redux";
import { useTriggerTransition } from "gatsby-plugin-transition-link";
import { createRipple, fade } from "../../layout/progTrans.js";
import {
  selectAECOMregions,
  selectAllarticles,
  selectCurrentlocale,
  selectGeo,
  selectProjects,
  selectUiTranslation,
} from "../../slices/contactSlice";
import { consoleLog } from "@utils/Helpers";
import { Link } from "gatsby";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const MenuInsights = ({ setisOpen, section, isOpen }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";
  const projects = useSelector(selectProjects);
  const articles = useSelector(selectAllarticles);
  const geo = useSelector(selectGeo);
  const Regions = useSelector(selectAECOMregions);

  const [sortedArticles, setsorted] = useState(null);
  const [Projects, setProjects] = useState(null);
  const dispatch = useDispatch();

  consoleLog("MenuInsights.js", {
    uiTranslation,
    currentlocale,
    projects,
    articles,
    geo,
    Regions,
    sortedArticles,
    Projects,
  });

  useEffect(() => {
    if (geo !== null) {
      //sets country projects
      let geoProjects =
        projects !== null &&
        projects.filter((el) => el.country.Name === geo.country_name);

      // sets global projects
      let globalProjects =
        projects !== null &&
        projects.filter((el) => el.country.Name === "Global");

      //if country projects is less than 4 then roll up to projects from the country continent
      if (geoProjects.length < 4 && geo.country_name !== undefined) {
        let continent = getContinent(geo.country_name, Regions);
        if (continent !== undefined) {
          for (let country of getContinent(geo.country_name, Regions)) {
            for (let project of projects) {
              if (
                project.country.Name.toLowerCase() ===
                  country.name.toLowerCase() &&
                project.country.Name.toLowerCase() !==
                  geo.country_name.toLowerCase()
              ) {
                geoProjects.push(project);
              }
            }
          }
        }

        // if there still are not enough add in projects tagged with global
        if (geoProjects.length < 4) {
          const shuffled = globalProjects.sort(() => 0.5 - Math.random());
          geoProjects = geoProjects.concat(shuffled.slice(0, 2));
        }
        // if there still are not enough projects tagged with global just get the latest projects
        if (geoProjects.length < 4) {
          geoProjects = projects;
        }
      }

      // Chunks the projects
      const ProjectChunk = flow(chunk(4))(geoProjects);
      setProjects(ProjectChunk[0]);

      // gets date ordered articles
      const sorted = flow(
        orderBy("date", "desc"),
        filter((a) => a.featured === false),
        chunk(4)
      )(articles);
      setsorted(sorted[0]);
    }
  }, [projects, geo]);

  const handleChange = () => {
    setisOpen((prevState) => ({
      ...prevState,
      [section]: prevState[section] ? false : true,
    }));
  };

  const [animating, setAnimating] = useState(false);
  // const Transition = useTransition({page:"/project_insight", type:null})

  const variant = isOpen[section] ? "opened" : "closed";

  const menuAnim = {
    closed: {
      top: "-90vh",
      transition: {
        duration: 0.2,
      },
    },
    opened: {
      top: "2rem",
      transition: {
        delay: 0.2,
        duration: 0.3,
        type: "spring",
      },
    },
  };
  const subListContainer = {
    closed: { opacity: 1, transition: { staggerChildren: 0.1 } },
    opened: {
      opacity: 1,

      transition: {
        delay: 3,
        staggerChildren: 0.2,
      },
    },
  };

  const subListItem = {
    closed: {
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
    opened: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
  };

  const leftSide = {
    closed: {
      opacity: 0,
      x: "-150px",
      transition: {
        duration: 0.1,
      },
    },
    opened: {
      opacity: 1,
      x: "0",
      transition: {
        delay: 0.2,
        duration: 0.4,
      },
    },
  };

  const rightSide = {
    closed: {
      opacity: 0,
      x: "150px",
      transition: {
        duration: 0.1,
      },
    },
    opened: {
      opacity: 1,
      x: "0",
      transition: {
        delay: 0.2,
        duration: 0.4,
      },
    },
  };

  const TriggerTansition = (link) => {
    return useTriggerTransition({
      to: link,
      exit: {
        length: 1,
        trigger: ({ exit, node, event }) =>
          createRipple(1, event, "#008768", node),
      },
      entry: {
        length: 0.5,
        delay: 0.5,
        trigger: ({ exit, node }) => fade({ exit, node, direction: "in" }),
      },
    });
  };

  return (
    <LazyMotion features={loadFeatures}>
      <m.div
        layoutId="Menu"
        onAnimationComplete={(definition) => {
          setAnimating(definition === "closed" ? false : true);
        }}
        animate={variant}
        initial={"closed"}
        variants={menuAnim}
        onMouseLeave={handleChange}
        className="z-30 w-full  h-1/2 hsm:h-2/3 fixed top-14 left-0 shadow-2xl text-black text-left bg-white "
      >
        <div className="flex w-full h-full ">
          <div className="w-3/12 h-full bg-AECOM_StoneGray-50 pt-6 z-20">
            {sortedArticles !== null ? (
              <m.div
                initial="closed"
                variants={leftSide}
                animate={variant}
                className="h-full"
              >
                <div className="w-full px-8 py-8 h-full">
                  <div className="flex flex-col h-full">
                    <div className="font-bold xl:text-lg text-base xl:mb-6 mb-4 flex-none h-10">
                      {uiTranslation.projects_insights[currentlocale]}
                    </div>
                    <div className="xl:h-14 mb-4 hidden xl:block flex-none">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0"
                        y="0"
                        height="100%"
                        viewBox="0 0 484.7 290.2"
                      >
                        <path d="M317.6 112.4c2.5-2.1 3.7-5.3 3.7-9.8V35c0-4.4-1.2-7.7-3.7-9.8-5-4.2-12.4-4.2-17.4 0-2.5 2.1-3.7 5.4-3.7 9.8v67.6c0 4.4 1.2 7.7 3.7 9.8 5 4.2 12.4 4.2 17.4 0m161-90.4h-21.2v110.8h-18.3V22.1h-21.2V4.8h60.8l-.1 17.2zm-69.5 82a29.8 29.8 0 0 1-41.1 27.5 29.8 29.8 0 0 1-18.1-27.5V4.8h18.3v97.5c0 4.6 1.1 7.9 3.2 10a11.8 11.8 0 0 0 16.2 0c2.1-2.1 3.2-5.5 3.2-10V4.8h18.4l-.1 99.2zm-69.5-1.4a29.7 29.7 0 0 1-19.7 29.3 32.3 32.3 0 0 1-32-5.8c-3-2.7-5.5-6-7.1-9.7-1.9-4.3-2.8-9-2.7-13.8V35c-.1-4.7.8-9.4 2.7-13.7a29 29 0 0 1 17.1-15.7 32.3 32.3 0 0 1 32 5.9c3 2.7 5.5 6.1 7.1 9.8 1.9 4.3 2.8 9 2.7 13.6l-.1 67.7zm-71 30.2h-18.3v-57h-22.6v57h-18.4V4.8h18.4v54.8h22.6V4.8h18.3v128zm-68-110.7h-21.2v110.8h-18.3V22.1h-21.2V4.8h60.8v17.3zm-69.8 110.7h-18.3V4.8h18.3v128zm-27.2-128-19 128H67.3l-14-83.1h-.4L39 132.8H21.8L2.7 4.8h19.4l9.2 81.3h.4L46.1 4.8h14L75 88.1h.3l8.8-83.3h19.5zM321 152.4a33.2 33.2 0 0 1 36.1 6.3c2.6 2.7 4.7 5.8 6.2 9.3a27 27 0 0 1 2.2 10.5v9h-18.3v-4.1c.1-3.8-.9-7.6-3-10.9-2-3.1-5.3-4.6-10-4.6a12 12 0 0 0-6.2 1.4c-1.6.9-2.9 2.2-3.8 3.7-.9 1.6-1.6 3.4-1.9 5.2a68.5 68.5 0 0 0-.2 12.6 10.3 10.3 0 0 0 4.5 7.8c1.8 1.2 3.7 2.1 5.7 2.9l14 5.6c3.6 1.3 6.9 3.1 9.9 5.5 2.5 2 4.5 4.5 6 7.4 1.4 3.1 2.4 6.4 2.8 9.7.5 4.2.7 8.3.7 12.5 0 5-.5 10.1-1.6 15-.9 4.3-2.7 8.3-5.3 11.9a24.8 24.8 0 0 1-9.8 7.8 36.3 36.3 0 0 1-27.3.6 29.8 29.8 0 0 1-18.9-28v-6.8h18.3v5.8c0 3.3 1 6.5 3 9.1 2 2.7 5.3 4 10 4 2.5.1 5-.3 7.3-1.4a9.2 9.2 0 0 0 4-3.8c1-1.9 1.6-3.9 1.7-6a92.5 92.5 0 0 0 0-16.2c-.1-1.8-.7-3.6-1.5-5.2a9.8 9.8 0 0 0-3.2-3.2 56 56 0 0 0-5.5-2.7l-13.1-5.4c-7.9-3.2-13.2-7.5-15.9-12.9s-4-12-4-20.1c0-4.6.7-9.2 2-13.7 1.2-4.1 3.2-7.8 5.9-11.1 2.7-3.2 6-5.8 9.8-7.5m-142.7-1.7h17.8v128h-18.5v-77.9h-.4l-18.2 55h-9.2l-18-55h-.4v77.9h-18.3v-128h17.6l23.6 67.8h.4l23.6-67.8zM39.5 260.4h36.3v18.3H21.2v-128h18.3v109.7zm45.8-109.7h18.3v128H85.3v-128zm122 0h18.3v128h-18.3v-128zm28.3 0h60.8V168h-21.2v110.8h-18.3V167.9h-21.2l-.1-17.2z" />
                      </svg>
                    </div>
                    <div className="w-full flex-grow ">
                      <p className="text-sm font-light">
                        Digital AECOM in action on our projects and thought
                        leadership that addresses the big issues of today and
                        tomorrow.
                      </p>
                    </div>
                    <div className="h-5 pt-auto text-bottom h-16 flex-none">
                      <Link to="/project_insight">
                        <div
                          // onClick={triggerTansition}
                          className="cursor-pointer ml-auto w-auto py-2 px-6 bg-AECOM_EarthGreen text-white text-base lg:text-sm rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mb-4"
                        >
                          {uiTranslation.Viewall[currentlocale]}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </m.div>
            ) : (
              <></>
            )}
          </div>
          <div className="lg:w-7/12 w-6/12 h-auto bg-white pt-6 ">
            <div className="flex px-8 py-8 h-full ">
              <div className="grid grid-cols-2 gap-x-10 gap-y-1 h-full">
                <div className=" h-full flex flex-col">
                  <div className="font-bold xl:text-lg text-base xl:mb-6 mb-4 flex-none h-10">
                    {uiTranslation.insights[currentlocale]}
                  </div>
                  <m.ul
                    className="flex-grow"
                    initial="closed"
                    variants={subListContainer}
                    animate={variant}
                  >
                    {sortedArticles !== null ? (
                      sortedArticles.map((document, index) => (
                        <m.li
                          whileHover={{
                            scale: 1.1,
                            transition: { duration: 0.15, type: "ease-out" },
                          }}
                          variants={subListItem}
                          key={`about${index}`}
                          className="font-light xl:text-sm text-xs hsm:text-xs text-black hsm:h-9 hsm:pb-0 h-14 pb-6 leading-tight transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left  w-11/12"
                        >
                          <LinkLang
                            key={`about${index}`}
                            to={document.slug}
                            section={"article"}
                          >
                            {document.title}
                          </LinkLang>
                        </m.li>
                      ))
                    ) : (
                      <></>
                    )}
                  </m.ul>
                  <div
                    onClick={TriggerTansition(
                      '/project_insight/?type=%7B"value"%3A"article"%2C"label"%3A"Insights"%2C"isdisabled"%3Afalse%7D'
                    )}
                  >
                    <m.div
                      // onClick={triggerTansition}
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.15, type: "ease-out" },
                      }}
                      variants={subListItem}
                      key={`artAll`}
                      className="cursor-pointer mt-6 font-bold xl:text-lg text-lg hsm:text-sm text-AECOM_EarthGreen hsm:h-9 hsm:pb-0  leading-tight transition ease-out duration-300 transform hover:text-black origin-left"
                    >{`${uiTranslation.Viewall[currentlocale]} ${uiTranslation.insights[currentlocale]}`}</m.div>
                  </div>
                </div>
                <div className="h-full flex flex-col">
                  <div className="font-bold xl:text-lg text-base xl:mb-6 mb-4 flex-none h-10">
                    {uiTranslation.projects[currentlocale]}
                  </div>
                  <m.ul
                    className="flex-grow"
                    initial="closed"
                    variants={subListContainer}
                    animate={variant}
                  >
                    {Projects !== null && Projects !== undefined ? (
                      Projects.map((document, index) => (
                        <m.li
                          whileHover={{
                            scale: 1.1,
                            transition: { duration: 0.15, type: "ease-out" },
                          }}
                          variants={subListItem}
                          key={`about${index}`}
                          className="font-light xl:text-sm text-xs hsm:text-xs text-black hsm:h-9 hsm:pb-0 h-14 pb-6 leading-tight transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left w-11/12"
                        >
                          <LinkLang
                            key={`about${index}`}
                            to={document.slug}
                            section={"project"}
                          >
                            {document.title}
                          </LinkLang>
                        </m.li>
                      ))
                    ) : (
                      <></>
                    )}
                  </m.ul>
                  <div
                    onClick={TriggerTansition(
                      '/project_insight/?type=%7B"value"%3A"project"%2C"label"%3A"Projects"%2C"isdisabled"%3Afalse%7D'
                    )}
                    // onClick={triggerTansition}
                  >
                    <m.div
                      whileHover={{
                        scale: 1.1,
                        transition: { duration: 0.15, type: "ease-out" },
                      }}
                      variants={subListItem}
                      key={`projAll`}
                      className="cursor-pointer font-bold xl:text-lg text-lg hsm:text-sm text-AECOM_EarthGreen hsm:h-9 hsm:pb-0 leading-tight transition ease-out duration-300 transform hover:text-black origin-left"
                    >{`${uiTranslation.Viewall[currentlocale]} ${uiTranslation.projects[currentlocale]}`}</m.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-2/12 hsm:w-2/12 w-3/12 h-full  bg-AECOM_StoneGray-50 pt-6 ">
            <div className="w-full h-full ">
              <m.div
                initial="closed"
                variants={rightSide}
                animate={variant}
                className="h-full"
              >
                <div className="flex flex-col h-full">
                  <RelatedMenu type="article" />
                </div>
              </m.div>
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default MenuInsights;
