import React, { useState } from "react";
import { Link } from "gatsby";
import { useSelector } from "react-redux";
import { motion, Transition, SVGMotionProps } from "framer-motion";
import Arrow from "./menuarrow.js";
import {
  selectCurrentlocale,
  selectUiTranslation,
} from "../../slices/contactSlice";

const MenuItem = ({ title, section, setisOpen, isOpen }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";

  // handles the click, creates a temp object resets all previous open menus and sets this section to open send ing the object back to the parent

  const handleChange = () => {
    let isTemp = isOpen;
    for (const property in isTemp) {
      if (property === section) {
        isTemp[section] = isTemp[section] ? false : true;
      } else {
        isTemp[property] = false;
      }
    }
    setisOpen((prevState) => ({
      ...prevState,
      ...isTemp,
    }));
  };

  return (
    <>
      {/* <div onClick={handleChange} className="flex w-full cursor-pointer "><div className={isOpen[section] ? "text-AECOM_EarthGreen font-semibold text-sm lg:text-base transition ease-out duration-300 transform hover:opacity-80 " :"text-white font-semibold text-sm lg:text-base transition ease-out duration-300 transform hover:text-AECOM_EarthGreen grow items-end"}>{uiTranslation[section][currentlocale]}</div><div className="inline w-7 lg:pt-2 pt-1.5 lg:pl-2.5 pl-2 text-left"><Arrow isOpen={isOpen[section]} topMenu={true} /></div></div> */}
      <div onClick={handleChange} className="flex w-full cursor-pointer ">
        {/* <div className={isOpen[section] ? "text-AECOM_EarthGreen font-semibold text-sm lg:text-base transition ease-out duration-300 transform hover:opacity-80 " :"text-white font-semibold text-sm lg:text-base transition ease-out duration-300 transform hover:text-AECOM_EarthGreen grow items-end"}>{uiTranslation[section][currentlocale]}</div> */}
        <div
          className={`${
            isOpen[section] ? "text-AECOM_EarthGreen" : "text-white"
          } ${
            section === "projects_insights" && "grow items-end"
          } font-semibold text-sm lg:text-base transition ease-out duration-300 transform hover:opacity-60`}
        >
          {uiTranslation[section][currentlocale]}
        </div>
        <div className="inline w-7 lg:pt-2 pt-1.5 lg:pl-2.5 pl-2 text-left">
          <Arrow isOpen={isOpen[section]} topMenu={true} />
        </div>
      </div>
    </>
  );
};

export default MenuItem;
