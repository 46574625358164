import React, { useEffect, useState } from "react";
// import { Link } from "gatsby"
import AniLink from "../layout/transLink";
import { connect } from "react-redux";
import withLocation from "../components/hoc/withlocation";
import { consoleLog } from "./Helpers";

const LinkLang = ({
  currentlocale = "en",
  children,
  location,
  returnPage,
  text,
  contactType,
  data,
  parentData,
  download,
  link,
  ...props
}) => {
  const [languageLocation, setLink] = useState(
    currentlocale === "en" ? props.to : `/${currentlocale}/${props.to}`
  );
  useEffect(() => {
    switch (props.section) {
      case "people":
        setLink(
          currentlocale === "en"
            ? `/people/${props.to}`
            : `/${currentlocale}/people/${props.to}`
        );
        break;
      case "service":
        setLink(
          currentlocale === "en"
            ? `/service/${props.to}`
            : `/${currentlocale}/service/${props.to}`
        );
        break;
      case "market":
        setLink(
          currentlocale === "en"
            ? `/market/${props.to}`
            : `/${currentlocale}/market/${props.to}`
        );
        break;
      case "article":
        setLink(
          currentlocale === "en"
            ? `/article/${props.to}`
            : `/${currentlocale}/article/${props.to}`
        );
        break;
      case "project":
        setLink(
          currentlocale === "en"
            ? `/project/${props.to}`
            : `/${currentlocale}/project/${props.to}`
        );
        break;
      case "product":
        setLink(
          currentlocale === "en"
            ? `/product/${props.to}`
            : `/${currentlocale}/product/${props.to}`
        );
        break;
      case "about":
        setLink(
          currentlocale === "en"
            ? `/about/${props.to}`
            : `/${currentlocale}/about/${props.to}`
        );
        break;
      case "solution":
        setLink(
          currentlocale === "en"
            ? `/solution/${props.to}`
            : `/${currentlocale}/solution/${props.to}`
        );
        break;
      default:
        setLink(
          currentlocale === "en"
            ? `/${props.to}`
            : `/${currentlocale}/${props.to}`
        );
    }
  }, [currentlocale]);

  if (
    props.to !== undefined &&
    props.to !== null &&
    (props.to.includes("http") || props.to.includes("mailto"))
  ) {
    return (
      <a href={props.to} target="_blank">
        {children}
      </a>
    );
  } else {
    if (returnPage !== null || returnPage !== undefined) {
      return (
        <AniLink
          to={languageLocation}
          state={{
            returnPage: returnPage,
            linkText: text,
            contactType: contactType,
            data: data,
            parentData: parentData,
            download: download,
            link: link,
          }}
          paintDrip
          hex="#008768"
        >
          {children}
        </AniLink>
      );
    } else {
      return (
        <AniLink to={languageLocation} paintDrip hex="#008768">
          {children}
        </AniLink>
      );
    }
  }
};

function mapStateToProps(state) {
  // consoleLog("mapStateToProps", state);
  return {
    currentlocale: state.contact.currentlocale,
  };
}
export default withLocation(connect(mapStateToProps, {})(LinkLang));
