import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import LinkLang from "../../utils/langLink.js";
import MenuArrow from "./menuarrow";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";
import {
  selectCurrentlocale,
  selectMarkets,
  selectUiTranslation,
} from "../../slices/contactSlice";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const MenuMarkets = ({ setisOpen, section, isOpen, setSubOpen, title }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";
  const markets = useSelector(selectMarkets);

  const handleChange = () => {
    setisOpen((prevState) => ({
      ...prevState,
      [section]: prevState[section] ? false : true,
    }));
    setSubOpen(false);
  };

  const Menuvariants = {
    subMenuClosed: {
      x: "200%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subMenuOpen: {
      x: "0",
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.3 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const subvariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  return (
    <LazyMotion features={loadFeatures}>
      <m.div
        variants={Menuvariants}
        animate={isOpen[section] ? "subMenuOpen" : "subMenuClosed"}
        className="absolute h-full w-full"
      >
        <m.ul
          variants={variants}
          animate={isOpen[section] ? "open" : "closed"}
          className="w-10/12 pt-16 mx-auto"
        >
          <m.li
            onClick={handleChange}
            variants={subvariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            className="cursor-pointer text-xl text-black mb-14 font-semibold transition ease-out duration-300 transform origin-left flex"
          >
            <div className="inline w-7  pr-2.5 mt-2.5 text-left transform rotate-90">
              <MenuArrow topMenu={false} />
            </div>
            <div>Back</div>
          </m.li>
          <m.li
            variants={subvariants}
            className="text-3xl text-AECOM_EarthGreen mb-12 font-semibold "
          >
            {title}
          </m.li>
          {markets !== null ? (
            markets[currentlocale].map((document, index) => (
              <m.li
                key={`about${index}`}
                variants={subvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className=" pl-4 cursor-pointer text-2xl xsm:text-xl  xsm:mb-6 text-black mb-10 font-semibold transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left"
              >
                <LinkLang
                  key={`about${index}`}
                  to={document.slug}
                  section={"market"}
                >
                  {document.Title}
                </LinkLang>
              </m.li>
            ))
          ) : (
            <></>
          )}
        </m.ul>

        <div className="absolute bottom-0 w-full h-20 bg-AECOM_StoneGray-50 pb-4">
          <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
            <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
              {/* <div className="font-bold text-lg mb-2 flex-none h-10">{uiTranslation.projects_insights[currentlocale]}</div> */}
              {/* <div className="w-full flex-grow ">
            <p className="text-sm font-light">Emquam, voluptur. Pererepratia pa anti repelen iminvent. Am nume labore porerci quo id molenecabo. Et et vendam consend elliquo et harcid erovit.</p>
            </div> */}
              <div className=" flex-none">
                <LinkLang to="project_insight">
                  <div className="cursor-pointer ml-auto w-auto py-2 px-6 bg-AECOM_EarthGreen text-white rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mt-6 mb-4">
                    {uiTranslation.projects_insights[currentlocale]}
                  </div>
                </LinkLang>
              </div>
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default MenuMarkets;
