import { TimelineMax } from "gsap";
import TransitionLink from "gatsby-plugin-transition-link";
import gsap from "gsap";
import convert from "color-convert";

export const fade = ({ exit: { length }, node, direction }) => {
  // console.log(node);

  const duration = direction === "out" ? length + length / 4 : length;
  const opacity = direction === "in" ? 1 : 0;
  const scrollTop =
    (document.scrollingElement && document.scrollingElement.scrollTop) ||
    document.body.scrollTop ||
    (typeof window !== `undefined` && window.pageYOffset);

  const holdPosition =
    direction === "out"
      ? {
          overflowY: "hidden",
          height: "100vh",
          scrollTop: scrollTop,
        }
      : {};

  return new TimelineMax()
    .set(node, holdPosition)
    .fromTo(node, duration, { opacity: !opacity }, { opacity: opacity });
};

export const createRipple = (length, event, hex, node) => {
  const body = document.body;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const piTwo = Math.PI * 2;

  let rgb = hex ? convert.hex.rgb(hex).join(",") : "0,0,255";

  canvas.style.zIndex = 10000;
  canvas.style.top = 0;
  canvas.style.position = "fixed";

  let vw = (canvas.width = window.innerWidth);
  let vh = (canvas.height = window.innerHeight);

  body.appendChild(canvas);

  // Event coords
  const x = 0;
  const y = 0;

  // Delta - difference between event and farthest corner
  const dx = x < vw / 2 ? vw - x : x;
  const dy = y < vh / 2 ? vh - y : y;

  const radius = Math.sqrt(dx * dx + dy * dy);

  const ripple = {
    alpha: 0,
    radius: 0,
    x: x,
    y: y,
  };

  const seconds = length;

  return gsap
    .timeline({
      onUpdate: drawRipple,
      onComplete: () => removeCanvas(seconds / 3),
    })
    .to(ripple, { alpha: 1, duration: seconds / 4 })
    .to(
      ripple,
      {
        radius: radius,
        ease: "power1.easeIn",
        duration: seconds - seconds / 3,
      },
      0,
    )
    .set(node, { visibility: "hidden" })
    .to(
      canvas,
      {
        x: "100%",
        ease: "power1.easeIn",
        duration: seconds / 3,
      },
      `+=${seconds * 0.4}`,
    );

  function drawRipple() {
    ctx.clearRect(0, 0, vw, vh);
    ctx.beginPath();
    ctx.arc(ripple.x, ripple.y, ripple.radius, 0, piTwo, false);
    const fillStyle = `rgba(${rgb},${ripple.alpha})`;
    ctx.fillStyle = fillStyle;
    ctx.fill();
  }

  window.addEventListener("resize", onResize);

  function removeCanvas(wait = 0) {
    setTimeout(() => {
      body.removeChild(canvas);
    }, wait);
  }

  function onResize() {
    vw = canvas.width = window.innerWidth;
    vh = canvas.height = window.innerHeight;
  }
};
