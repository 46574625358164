import React, { useState, useEffect } from 'react';

interface Props {
  disabled?: boolean;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const ToggleAll = ({ disabled, checked, setChecked }: Props) => {
  let toggleRef = React.createRef() as any;

  return (
    <div
      className={
        disabled
          ? 'mb-12 flex w-full items-center justify-center pl-4 opacity-60'
          : 'mb-12 flex w-full items-center  justify-center pl-4'
      }
    >
      <label className='cursor-pointer items-center md:flex'>
        <div className='relative'>
          <input
            onChange={() => setChecked(!checked)}
            type='checkbox'
            ref={toggleRef}
            className='sr-only'
            checked={checked}
            disabled={disabled}
          />

          <div className='block h-8 w-14 rounded-full border-2 border-white'></div>

          <div className='dot absolute left-1 top-1 h-6 w-6 rounded-full bg-white transition'></div>
        </div>
        <div className='ml-0 mt-4 font-medium text-white md:ml-3 md:mt-0 '>
          {checked  ? 'All allowed' : 'All denied'}
        </div>
      </label>
    </div>
  );
};

export default ToggleAll;
