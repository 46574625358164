import React from "react";
import withLocation from "../hoc/withlocation";
// import {useSelector} from 'react-redux';
import LinkLang from "../../utils/langLink.js";

const ContactButton = ({ location, ...props }) => {
  // const uiTranslation = useSelector(selectUiTranslation)

  if (location.pathname !== "/contact") {
    return (
      <div className="z-20">
        <LinkLang
          tabIndex="0"
          to="contact"
          returnPage={location.pathname}
          text="back"
          contactType="enquiry"
        >
          <div className="hidden md:block transform rotate-90 shadow-lg font-bold text-white p-2 z-20 fixed top-44 -right-12 w-32 h-auto text-center bg-AECOM_EarthGreen cursor-pointer transition ease-out duration-300 hover:scale-110">
            Contact us
          </div>
        </LinkLang>
      </div>
    );
  } else {
    return <></>;
  }
};

export default withLocation(ContactButton);
