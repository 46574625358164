import React, { Suspense, lazy, useEffect } from "react";
import { withPrefix, navigate } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import ScrollIcon from "./scrollicon";
import ContactButton from "../components/contact/contactButton";
import { useDispatch } from "react-redux";
// import { setpageData, getLocation } from "../actions";
import { ArticlesQuery } from "../constants";
import ScrollTrigger from "gsap/ScrollTrigger";
import withLocation from "../components/hoc/withlocation";
import { isIE } from "react-device-detect";
// import CookieConsent from "react-cookie-consent";
import {
  NewSetPageData,
  NewSetTracking,
  getLocation,
  setGeo,
} from "../slices/contactSlice";

import { consoleLog, isSSR } from "../utils/Helpers";
import { useSiteData } from "../hooks/useSiteData";
import { useIsClient } from "../utils/useIsClient";
import Consent from "../components/ui/consent/Consent";

// <Scroll callbacks={location} />
// <Transition location={location}>{children}</Transition>

type Props = {
  children: React.ReactNode;
  location: {
    pathname: string;
  };
};

const TemplateWrapper = ({ children, location, ...props }: Props) => {
  let pagedata = ArticlesQuery();
  const dispatch = useDispatch();
  const site = useSiteData();
  const isClient = useIsClient();
  const onResize = () => {
    ScrollTrigger.refresh(true);
  };

  const FingerPrintJS = () => {
    if (!isSSR) {
      const FingerPrint = lazy(
        () => import("../components/tracking/Fingerprint")
      );

      return (
        <Suspense fallback={<div>Loading...</div>}>
          <FingerPrint />
        </Suspense>
      );
    }
  };
  useEffect(() => {
    const language =
      // @ts-ignore
      (typeof window !== "undefined" && window.navigator.userLanguage) ||
      window.navigator.language;
    dispatch(getLocation());
    // dispatch(setpageData(pagedata));
    dispatch(
      NewSetTracking({
        data: site.siteInfo,
      })
    );
    // consoleLog("pagedata", pagedata);
    dispatch(NewSetPageData(pagedata));
    // dispatch(setGeo(language));

    // // props.getLocation()
    // props.setpageData(pagedata)

    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResize, false);
    }

    // returned function will be called on component unmount
    return () => {
      if (typeof window !== "undefined") {
        // you need to unbind the same listener that was binded.
        window.removeEventListener("resize", onResize, false);
      }
    };
  }, []);

  if (isIE && location.pathname !== withPrefix("/error")) {
    navigate("/error");
    return null;
  }

  return isIE ? (
    <div>{children}</div>
  ) : (
    <div className="relative">
      <Header location={location} />

      <div>{children}</div>
      <Consent />
      <Footer location={location} />
      <ScrollIcon />
      {isClient && <FingerPrintJS />}

      <ContactButton />
    </div>
  );
};

export default withLocation(TemplateWrapper);
