import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LinkLang from "../../utils/langLink.js";
import { BgImage } from "gbimage-bridge";
// import { getImage } from "gatsby-plugin-image"
import { returnImage } from "../../utils";
import withLocation from "../hoc/withlocation";
import { flow, orderBy, filter } from "lodash/fp";
import {
  selectAllarticles,
  selectCurrentlocale,
  selectProjects,
  selectUiTranslation,
} from "../../slices/contactSlice";
const RelatedMenu = ({ type, location, data, ...props }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";
  const allarticles = useSelector(selectAllarticles);
  const projects = useSelector(selectProjects);
  const [allContent, setAllContent] = useState(null);

  let All = [];

  useEffect(() => {
    if (currentlocale === "en") {
      if (type === "article") {
        let featured = flow(
          orderBy("date", "desc"),
          filter((a) => a.featured === true)
        )(allarticles);
        setAllContent(featured[0]);
      }
    }
  }, [allarticles, projects]);

  if (allContent !== null) {
    return (
      <div className="w-full h-full grow">
        <LinkLang
          to={allContent.slug}
          section={allContent.type}
          returnPage={location.pathname}
          text={
            location.pathname.includes("project_insight")
              ? uiTranslation.projects_insights[currentlocale]
              : uiTranslation.back[currentlocale]
          }
        >
          <BgImage
            image={returnImage(allContent.image.localFile, "#000000", 50)}
            Tag="div"
            className="h-full cursor-pointer w-full p-5 "
          >
            {allContent.type === "article" && (
              <div className="subpixel-antialiased uppercase mt-4  text-white tracking-widest">
                Featured {uiTranslation.insight["en"]}
              </div>
            )}
            {allContent.type === "project" && (
              <div className="subpixel-antialiased transform rotate-90 absolute -right-3.5 top-12 uppercase text-white font-bold tracking-widest">
                Featured {uiTranslation.project["en"]}
              </div>
            )}

            <div className="h-full  w-10/12 transition ease-out duration-300 transform hover:scale-105">
              <div className="subpixel-antialiased text-white md:text-xl h-full font-semibold w-full mt-24 ">
                {allContent.title}
              </div>
            </div>
          </BgImage>
        </LinkLang>
      </div>
    );
  } else {
    return <></>;
  }
};

export default withLocation(RelatedMenu);
