import { getImage } from "gatsby-plugin-image";
import { consoleLog } from "./Helpers";

export function LightOrDark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;
  if (color !== null) {
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return "light";
    } else {
      return "dark";
    }
  }
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function returnImage(image, color, opacity) {
  let pluginImage = getImage(image);

  let rgb =
    color !== null && color !== undefined
      ? color.includes("#")
        ? hexToRgb(color)
        : color
      : hexToRgb("#000000");
  let finalOp = opacity !== null || opacity !== undefined ? opacity / 100 : 50;

  if (pluginImage === null || pluginImage === "") {
    return [
      `radial-gradient(circle, rgba(${rgb.r},${rgb.g},${rgb.b},${
        finalOp * 0.9430147058823529
      }) 25%, rgba(${rgb.r},${rgb.g},${rgb.b},0.8645833333333334) 100%)`,
    ].reverse();
  } else {
    return [
      pluginImage,
      `radial-gradient(circle, rgba(${rgb.r},${rgb.g},${rgb.b},${
        finalOp * 0.9430147058823529
      }) 25%, rgba(${rgb.r},${rgb.g},${rgb.b},0.8645833333333334) 100%)`,
    ].reverse();
  }
}

export function getColour(color) {
  let Colour = "#008768";
  switch (color) {
    case "AECOM_MidnightTeal":
      Colour = "#00353e";
      break;
    case "AECOM_EarthGreen":
      Colour = "#008768";
      break;
    case "AECOM_LimeGreen":
      Colour = "#aecc53";
      break;
    case "AECOM_CoralOrange":
      Colour = "#75B843";
      break;
    case "AECOM_OceanTeal":
      Colour = "#009a9b";
      break;
    case "AECOM_FuchsiaPink":
      Colour = "#c70c6f";
      break;
    case "AECOM_SunriseYellow":
      Colour = "#ffce00";
      break;
    case "AECOM_StoneGray":
      Colour = "#dad8cc";
      break;
    case "white":
      Colour = "#ffffff";
      break;
    case "black":
      Colour = "#000000";
      break;
    default:
  }

  return Colour;
}

export function getScreenDimensions() {
  let dimensions = { width: 0, height: 0 };

  if (typeof window !== "undefined") {
    dimensions.width = window.innerWidth;
    dimensions.height = window.innerHeight;
  }
  return dimensions;
}

export function getRegion(region) {
  let Region = "Global";

  switch (region) {
    case "MiddleEastAfrica":
      Region = "Middle East and Africa";
      break;
    case "APAC":
      Region = "Asia Pacific";
      break;
    case "Americas":
      Region = "Americas";
      break;
    case "Europe":
      Region = "Europe";
      break;
    case "Global":
      Region = "Global";
      break;

    default:
  }

  return Region;
}

export function getContinent(country, regions) {
  consoleLog("getContinent", { country, regions });
  for (let region of regions.Region) {
    for (let countrys of region.data) {
      // if(countrys.name.toLowerCase() === country.toLowerCase())
      // if(countrys.name.toLowerCase().includes(country.toLowerCase()))

      if (country.toLowerCase().includes(countrys.name.toLowerCase()))
        return region.data;
    }
  }
}

export function SplitSrcSet(srcset) {
  // console.log(srcset);
  let sources = [];

  for (var descriptor of srcset.split(/,/)) {
    var imageSize = descriptor.split(/[, ]+/).pop().replace("w", "");
    var mediaQuery = descriptor.substring(-imageSize.length).split(" ");
    sources.push({ size: imageSize, src: mediaQuery[0] });
  }
  let goal = window.innerWidth;
  return sources.reduce((prev, curr) =>
    Math.abs(curr.size - goal) < Math.abs(prev.size - goal) ? curr : prev
  );
}
