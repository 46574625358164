import React, { useState, useEffect } from "react";

interface Props {
  disabled?: boolean;
  setConsentOptions: any;
  consentName: string;
  consentOptions: any;
}

const Toggle = ({
  disabled,
  setConsentOptions,
  consentName,
  consentOptions,
}: Props) => {
  const [consentAllow, toggleAllow] = useState("Allowed");
  const [checked, setChecked] = useState(true);

  let toggleRef = React.createRef() as any;

  useEffect(() => {
    if (consentOptions[consentName]) {
      toggleAllow("Allowed");
      setChecked(true);
    } else {
      toggleAllow("Blocked");
      setChecked(false);
    }
  }, [consentOptions]);

  const toggle = () => {
    consentAllow === "Allowed"
      ? toggleAllow("Blocked")
      : toggleAllow("Allowed");
    checked ? setChecked(false) : setChecked(true);

    setConsentOptions((prevState: any) => ({
      ...prevState,
      [consentName]: prevState[consentName] ? false : true,
    }));
  };

  return (
    <div
      className={
        disabled
          ? "opacity-60  flex items-center justify-center w-full mb-12"
          : "flex items-center justify-center w-full mb-12"
      }
    >
      <label className="md:flex items-center cursor-pointer">
        <div className="relative">
          <input
            onChange={toggle}
            type="checkbox"
            ref={toggleRef}
            className="sr-only"
            checked={checked}
            disabled={disabled}
          />

          <div className="block border-white border-2 w-14 h-8 rounded-full"></div>

          <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
        </div>
        <div className="md:ml-3 md:mt-0 mt-4 ml-0 text-white font-medium ">
          {consentAllow}
        </div>
      </label>
    </div>
  );
};

export default Toggle;
