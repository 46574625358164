/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/css/styles.css";
import { isSSR, pushToDataLayer } from "./src/utils/Helpers";
import wrapWithProvider from "./wrap-with-provider";

!isSSR &&
  window.gtag !== undefined &&
  window.gtag("consent", "default", {
    ad_storage: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "denied",
  });
export const onRouteUpdate = () => {
  setTimeout(() => {
    pushToDataLayer({ event: "aecom_route_change" });
  }, 500);
};

export const wrapRootElement = wrapWithProvider;

// export const onClientEntry = async () => {
//   if (typeof IntersectionObserver === `undefined`) {
//     await import(`intersection-observer`);
//   }
// }

// export const shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
//   }) => {
//     const currentPosition = getSavedScrollPosition(location)
//     const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

//     window.scrollTo(...(currentPosition || [0, 0]))

//     return false
//   }

// export const shouldUpdateScroll = () => true
