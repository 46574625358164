import * as React from "react";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const MenuToggle = ({ toggle }) => {
  const pathProps = {
    strokeWidth: "3",
    strokeLinecap: "round",
  };

  return (
    <LazyMotion features={loadFeatures}>
      <button
        onClick={toggle}
        aria-label="Menu"
        className="z-50 fixed top-4 right-4"
      >
        <svg width="30" height="30" viewBox="0 0 23 23">
          <m.path
            variants={{
              closed: {
                d: "M 2 2.5 L 20 2.5",
                stroke: "#ffffff",
                fill: "#ffffff",
              },
              open: {
                d: "M 3 16.5 L 17 2.5",
                stroke: "#ffffff",
                fill: "#ffffff",
              },
            }}
            {...pathProps}
          />
          <m.path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1, stroke: "#ffffff", fill: "#ffffff" },
              open: { opacity: 0, stroke: "#ffffff", fill: "#ffffff" },
            }}
            transition={{ duration: 0.1 }}
            {...pathProps}
          />
          <m.path
            variants={{
              closed: {
                d: "M 2 16.346 L 20 16.346",
                stroke: "#ffffff",
                fill: "#ffffff",
              },
              open: {
                d: "M 3 2.5 L 17 16.346",
                stroke: "#ffffff",
                fill: "#ffffff",
              },
            }}
            {...pathProps}
          />
        </svg>
      </button>
    </LazyMotion>
  );
};

export default MenuToggle;
