import React, { useEffect, useState } from "react";
// import { motion, useCycle } from "framer-motion";
import MenuToggle from "./menuToggle.js";
import TopLevel from "./hamTopLevelNav.js";

// import { motion, Transition} from "framer-motion";
import { LazyMotion, m, useCycle } from "framer-motion";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const HamMenu = ({ location }) => {
  const [dimensions, setDimenions] = useState({ width: 0, height: 0 });
  const [isHamOpen, toggleOpen] = useCycle(false, true);
  const [Prevlocation, setLocation] = useCycle(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setDimenions({ width: window.innerWidth, height: window.innerHeight });
      window.addEventListener(
        "resize",
        function (event) {
          setDimenions({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        },
        true,
      );
    }
  }, []);

  useEffect(() => {
    isHamOpen && toggleOpen();
  }, [location]);

  if (dimensions.width !== 0) {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div initial={false} animate={isHamOpen ? "open" : "closed"}>
          <MenuToggle toggle={() => toggleOpen()} />

          <m.div
            className="fixed bg-white w-full h-full top-0 left-0 bottom-0 z-40"
            variants={{
              open: {
                clipPath: `circle(${dimensions.height * 2 + 200}px at ${
                  dimensions.width
                }px -10px)`,
                transition: {
                  type: "spring",
                  stiffness: 20,
                  restDelta: 2,
                },
              },
              closed: {
                clipPath: `circle(10px at ${dimensions.width}px -10px)`,
                transition: {
                  delay: 0.5,
                  type: "spring",
                  stiffness: 400,
                  damping: 40,
                },
              },
            }}
          >
            <TopLevel isHamOpen={isHamOpen} />
          </m.div>
        </m.div>
      </LazyMotion>
    );
  } else {
    return <></>;
  }
};

export default HamMenu;
