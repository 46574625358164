import React, { useState } from "react";
import { useSelector } from "react-redux";
import LinkLang from "../../utils/langLink.js";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";
import {
  selectCurrentlocale,
  selectUiTranslation,
} from "../../slices/contactSlice";
import { consoleLog } from "../../utils/Helpers";
const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const SubMenu = ({ pillarText, section, title, isOpen, introText, ham }) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";

  const variant = pillarText === null ? "closed" : "opened";
  const openVarient = isOpen ? "opened" : "closed";

  const leftSide = {
    closed: {
      opacity: 0,
      x: "-150px",
      transition: {
        duration: 0.02,
      },
    },
    opened: {
      opacity: 1,
      x: "0",
      transition: {
        delay: 0.2,
        duration: 0.2,
      },
    },
  };

  // consoleLog("Discover More", uiTranslation.discoverMore[currentlocale]);

  if (pillarText !== null) {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div
          initial="closed"
          variants={leftSide}
          animate={variant}
          className="h-full"
        >
          <div className="flex flex-col h-full">
            <div className="w-full flex-grow ">
              {!ham ? (
                <div className="font-bold xl:text-lg text-base text-AECOM_EarthGreen mb-4">
                  {pillarText.title !== undefined
                    ? pillarText.title
                    : pillarText.Title}
                </div>
              ) : (
                <></>
              )}
              <p className="text-sm font-light">
                {pillarText.meta_description}
              </p>
            </div>
            <div className="h-5 pt-auto text-bottom h-16 flex-none">
              <LinkLang to={`${section}/${pillarText.slug}`}>
                <div className="cursor-pointer mr-auto xl:w-3/4 lg:w-full py-2 px-6 bg-AECOM_EarthGreen text-white rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mt-6 mb-4">
                  {uiTranslation.discoverMore[currentlocale]}
                </div>
              </LinkLang>
            </div>
          </div>
        </m.div>
      </LazyMotion>
    );
  } else if (introText !== null) {
    return (
      <LazyMotion features={loadFeatures}>
        <m.div initial="closed" variants={leftSide} animate={openVarient}>
          <div className="font-bold xl:text-lg text-base text-AECOM_EarthGreen mb-4">
            {title}
          </div>
          <p className="text-sm font-light">{introText}</p>
        </m.div>
      </LazyMotion>
    );
  } else {
    return <></>;
  }
};

export default SubMenu;
