import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import sortBy from "lodash/fp/sortBy";
import LinkLang from "../../utils/langLink.js";

import SubMenu from "./subMenu.js";
import MenuText from "./menutext.js";
import MenuArrow from "./menuarrow";
// import { motion, Transition} from "framer-motion";
import { LazyMotion, m } from "framer-motion";
import {
  selectCurrentlocale,
  selectService,
  selectServices,
  selectUiTranslation,
} from "../../slices/contactSlice";

const loadFeatures = () =>
  import("./motionfeatures.js").then((res) => res.default);

const MenuService = ({
  setisOpen,
  section,
  isOpen,
  setSubOpen,
  title,
  isHamOpen,
}) => {
  const uiTranslation = useSelector(selectUiTranslation);
  const currentlocale = useSelector(selectCurrentlocale) || "en";
  const services = useSelector(selectServices);
  const service = useSelector(selectService);
  const [subMenu, setSubMenu] = useState(null);
  const [pillarText, setPillarText] = useState(null);
  const [selected, setSelected] = useState(null);
  const [introText, setInto] = useState(
    uiTranslation[`${section}intro`][currentlocale]
  );

  const changeService = (slug, e) => {
    setInto(null);
    setSubMenu(null);
    setPillarText(null);
    let sorted = sortBy("slug")(service[currentlocale][slug]);

    setSelected(slug);

    const timer2 = setTimeout(() => {
      setSubMenu(sorted);
      setPillarText(services[currentlocale].find((el) => el.slug === slug));
    }, 300);
  };

  const closeSub = () => {
    setInto(null);
    setSubMenu(null);
    setPillarText(null);
    setInto(uiTranslation[`${section}intro`][currentlocale]);
  };

  const handleChange = () => {
    setisOpen((prevState) => ({
      ...prevState,
      [section]: prevState[section] ? false : true,
    }));
    setSubOpen(false);
  };

  const Menuvariants = {
    subMenuClosed: {
      x: "200%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subMenuOpen: {
      x: "0",
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const subMenuvariants = {
    subMenuOpen: {
      x: "-200%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subMenuClosed: {
      x: "0",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subsubMenuOpen: {
      x: "200%",
      transition: {
        x: { stiffness: 1000 },
      },
    },
    subsubMenuClosed: {
      x: "0",
      transition: {
        x: { stiffness: 1000 },
      },
    },
  };

  const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.3 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const subvariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  useEffect(() => {
    if (!isHamOpen) {
      setSubMenu(null);
      setPillarText(null);
      setSelected(null);
    }
  }, [isHamOpen]);

  return (
    <LazyMotion features={loadFeatures}>
      <m.div
        variants={Menuvariants}
        animate={isOpen[section] ? "subMenuOpen" : "subMenuClosed"}
        className="absolute h-full w-full"
      >
        <div className="w-10/12 pt-16 mx-auto">
          <m.div
            variants={subMenuvariants}
            animate={subMenu ? "subMenuOpen" : "subMenuClosed"}
            className="absolute h-full w-full"
          >
            <m.ul
              variants={variants}
              animate={isOpen[section] ? "open" : "closed"}
              className="width-full"
            >
              <m.li
                onClick={handleChange}
                variants={subvariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className="cursor-pointer text-xl text-black mb-14 font-semibold transition ease-out duration-300 transform origin-left flex"
              >
                <div className="inline w-7  pr-2.5 mt-2.5 text-left transform rotate-90">
                  <MenuArrow topMenu={false} />
                </div>
                <div>Back</div>
              </m.li>
              <m.li
                variants={subvariants}
                className="text-3xl text-AECOM_EarthGreen mb-12 font-semibold "
              >
                {title}
              </m.li>
              {services !== null ? (
                services[currentlocale].map((document, index) => (
                  <m.li
                    key={`service${index}`}
                    variants={subvariants}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    className="pl-4  cursor-pointer text-2xl xsm:text-xl xsm:mb-6 text-black mb-10 font-semibold transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left"
                    onClick={(e) => changeService(document.slug, e)}
                  >
                    {document.title}
                  </m.li>
                ))
              ) : (
                <></>
              )}
            </m.ul>
          </m.div>

          <m.div
            variants={subMenuvariants}
            animate={!subMenu ? "subsubMenuOpen" : "subsubMenuClosed"}
            className="h-full w-full"
          >
            <div className="">
              <div className="">
                <m.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="cursor-pointer text-xl text-black mb-16 font-semibold transition ease-out duration-300 transform hover:text-AECOM_EarthGreen origin-left flex"
                  onClick={closeSub}
                >
                  <div className="inline w-7  pr-2.5 mt-2.5 text-left transform rotate-90">
                    <MenuArrow topMenu={false} />
                  </div>
                  <div>{`Back to ${section}`}</div>
                </m.div>
                {pillarText !== null && (
                  <m.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    className="text-3xl text-AECOM_EarthGreen mb-10 font-semibold origin-left "
                  >
                    <LinkLang to={pillarText.slug} section={section}>
                      {pillarText.title}
                    </LinkLang>
                  </m.div>
                )}
                <SubMenu data={subMenu} ham={true} linksection="service" />
              </div>
            </div>
          </m.div>
        </div>
        {/* <div className="absolute bottom-0 w-full h-1/4 bg-AECOM_StoneGray-50 pt-4">
           <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
           <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
            <MenuText pillarText={pillarText} ham={true} section={section} title={title} isOpen={isOpen[section]} introText={introText}/>
            </div>
            </div>
       </div> */}

        <div className="absolute bottom-0 w-full h-20 bg-AECOM_StoneGray-50 pb-4">
          <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
            <div className="z-50 w-11/12 absolute text-left transform -translate-x-1/2 left-1/2">
              {/* <div className="font-bold text-lg mb-2 flex-none h-10">{uiTranslation.projects_insights[currentlocale]}</div> */}
              {/* <div className="w-full flex-grow ">
            <p className="text-sm font-light">Emquam, voluptur. Pererepratia pa anti repelen iminvent. Am nume labore porerci quo id molenecabo. Et et vendam consend elliquo et harcid erovit.</p>
            </div> */}
              <div className=" flex-none">
                <LinkLang to="project_insight">
                  <div className="cursor-pointer ml-auto w-auto py-2 px-6 bg-AECOM_EarthGreen text-white rounded-3xl font-semibold transition ease-out duration-300 transform hover:opacity-80 hover:scale-110 mt-6 mb-4">
                    {uiTranslation.projects_insights[currentlocale]}
                  </div>
                </LinkLang>
              </div>
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default MenuService;
