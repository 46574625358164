import { pushToDataLayer } from "../../utils/Helpers";

const GATags = [
  "UA-84589785-40",
  "G-XKTMQZ4GKR",
  "G-Y1G8KVG2ZL",
  "UA-221122267-1",
];

export function setConsent() {
  if (localStorage.getItem("DigitalAECOMConsent") !== null) {
    const Settings = JSON.parse(localStorage.getItem("DigitalAECOMConsent"));

    setGAtracking(Settings.general);
    setGTMtracking(Settings.general);
    setGAContent(Settings.content);
    setWistia(Settings.content);
    setGTMsecurity(Settings.security);
    setClarity(Settings.general);
    // typeof window !== "undefined" &&
    //   window.gtag !== undefined &&
    //   window.gtag("event", "consent_update", {
    //     event_category: "consent_update",
    //     event_label: "consent_update",
    //   });
    pushToDataLayer({
      event: "consent_update",
      event_category: "consent_update",
      event_label: "consent_update",
    });
  }
}

export function checkConsent(consent) {
  if (localStorage.getItem("DigitalAECOMConsent") !== null) {
    const Settings = JSON.parse(localStorage.getItem("DigitalAECOMConsent"));

    return Settings[consent];
  } else {
    return false;
  }
}

export function setDefaults() {
  if (typeof window !== "undefined") {
    var wistiaScript = document.createElement("script");
    wistiaScript.id = "wistia_script";
    wistiaScript.type = "text/javascript";
    wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
    wistiaScript.async = true;
    document.body.appendChild(wistiaScript);
    window._wq = window._wq || [];
    window._wq.push(function (W) {
      W.consent(false);
    });
  }
  typeof window !== "undefined" &&
    window.gtag !== undefined &&
    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied",
    });
}

export function setWistia() {
  if (typeof window !== "undefined") {
    var wistiaScript = document.createElement("script");
    wistiaScript.id = "wistia_script";
    wistiaScript.type = "text/javascript";
    wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
    wistiaScript.async = true;
    document.body.appendChild(wistiaScript);

    window._wq = window._wq || [];
    window._wq.push(function (W) {
      W.consent(true);
    });
  }
}

export function setGAtracking(consent) {
  typeof window !== "undefined" &&
    window.gtag !== undefined &&
    window.gtag("js", new Date());
  for (let GA of GATags) {
    if (consent) {
      typeof window !== "undefined" &&
        window.gtag !== undefined &&
        window.gtag("config", GA, {
          anonymize_ip: "false",
          cookie_expires: 365 * 24 * 60 * 60,
          link_attribution: "true",
          send_page_view: "true",
        });
      // typeof window !== 'undefined' && window.gtag('config', GA ,{ 'anonymize_ip': 'false','cookie_prefix': 'AECOM_GA', 'cookie_domain': 'aecom.com', 'cookie_expires': 365 * 24 * 60 * 60, 'link_attribution': 'true', 'send_page_view':'true' } )
      typeof window !== "undefined" &&
        window.gtag !== undefined &&
        window.gtag("consent", "update", {
          analytics_storage: "granted",
          functionality_storage: "granted",
        });
    } else {
      typeof window !== "undefined" &&
        window.gtag !== undefined &&
        window.gtag("config", GA, {
          anonymize_ip: "true",
          cookie_expires: 0,
          link_attribution: false,
          send_page_view: false,
        });
      typeof window !== "undefined" &&
        window.gtag !== undefined &&
        window.gtag("consent", "update", {
          analytics_storage: "denied",
          functionality_storage: "denied",
        });
      //delete cookie
      deleteCookie("_g");
    }
  }
}

export function setGTMtracking(consent) {
  if (consent) {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", {
        analytics_storage: "granted",
        functionality_storage: "granted",
      });
  } else {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", {
        analytics_storage: "denied",
        functionality_storage: "denied",
      });
    deleteCookie("_g");
  }
}

export function setGAContent(consent) {
  if (consent) {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", {
        ad_storage: "granted",
        ads_data_redaction: "false",
        personalization_storage: "granted",
      });
  } else {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", {
        ad_storage: "denied",
        ads_data_redaction: "true",
        personalization_storage: "denied",
      });
  }
}

export function setGTMsecurity(consent) {
  if (consent) {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", { security_storage: "granted" });
  } else {
    typeof window !== "undefined" &&
      window.gtag !== undefined &&
      window.gtag("consent", "update", { security_storage: "denied" });
  }
}

export function setClarity(consent) {
  if (consent) {
    typeof window !== "undefined" &&
      window.clarity !== undefined &&
      window.clarity("consent");
  } else {
  }
}

export function deleteCookie(cookie_name) {
  document.cookie.split(";").forEach(function (cookie) {
    var cookieName = cookie.trim().split("=")[0];
    // If the prefix of the cookie's name matches the one specified, remove it
    if (cookieName !== null) {
      if (cookieName.indexOf(cookie_name) === 0) {
        document.cookie =
          cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
  });
}
